<template>
  <div id="pdfvuer">
    <pdf
      :src="pdfdata"
      v-for="i in numPages"
      :key="i"
      :id="i"
      :page="i"
      :scale.sync="scale"
      style="width: 100%; margin: 20px auto"
      :annotation="true"
      :resize="true"
    >
    </pdf>
    <div class="text-center my-5" v-if="loading">
      <v-progress-circular width="4" size="40" indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import pdfvuer from 'pdfvuer';

export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  components: {
    pdf: pdfvuer
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 'page-width',
      loading: true
    };
  },
  mounted() {
    this.getPdf();
  },
  methods: {
    getPdf() {
      var self = this;
      self.pdfdata = pdfvuer.createLoadingTask(this.url);
      self.pdfdata.then(pdf => {
        self.numPages = pdf.numPages;
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
